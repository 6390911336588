import React from 'react';

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <div id="footer" className="app-footer">
            Copyright &copy; {currentYear} Gale Force AI LLC. All Rights Reserved.
        </div>
    );
}

export default Footer;