import React from 'react';

function AINotes() {
	return (
		<div>

		
			<h1 className="page-header">
				AI Notes <small></small>
			</h1>
		
			<p>
				
			</p>
		</div>
	)
}

export default AINotes;