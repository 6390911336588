import React, { useContext, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import MicRecorder from 'mic-recorder-to-mp3';
import { AppSettings } from './../../config/app-settings.js';
import AIChatbotTopicData from '../../assets/data/aichatbot/topics.json';
import Config from '../config.json';
import galeProfilePic from '../../assets/img/aichatbot/gale_profile_1.jpg';
import robotProfilePic from '../../assets/img/aichatbot/cute_colorful_robot.jpg';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
const WS_URL = 'wss://6aiolymuhf.execute-api.us-east-2.amazonaws.com/prod';

function AIChatbot() {
    const [chatWith, setChatWith] = useState('');
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [pageHeader, setPageHeader] = useState(null);
    const [showProgress, setShowProgress] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    const [isRecording, setIsRecording] = useState(false);
    const [output, setOutput] = useState(null);

    const context = useContext(AppSettings);
	const messagesEndRef = useRef(null);
    const textareaRef = useRef(null);
    const { topic } = useParams();

    const getChatTopicData = () => {
        if (topic.includes('-')) {
            // const parts = topic.split('-');  // Split the text by the hyphens
            // const team_id = parts.slice(0, 3).join('-');  // Join the first three parts for the team_id.
            // const contact_id = parts[3];  // contact_id

            return AIChatbotTopicData['socrates'];
        } else if (AIChatbotTopicData[topic]) {
            return AIChatbotTopicData[topic];
        } else {
            return AIChatbotTopicData['default'];
        }

        const firstTwoChars = topic.slice(0, 2).toUpperCase();

        if (firstTwoChars === 'ZH') {
            return AIChatbotTopicData['socrates'];
        } else if (AIChatbotTopicData[topic]) {
            return AIChatbotTopicData[topic];
        } else {
            return AIChatbotTopicData['default'];
        }
    }

    const chatTopicData = getChatTopicData();
    
    if (!localStorage.getItem('user_id')) {
        localStorage.setItem(
            'user_id', 
            `${topic}-${Intl.DateTimeFormat().resolvedOptions().timeZone}-${window.screen.width}x${window.screen.height}-${Math.floor(Math.random() * 10000) + 1}`
        );
    }

    let userId = localStorage.getItem('user_id');
    
    const { getWebSocket } = useWebSocket(WS_URL + '?sourceSystemUrl=' + window.location.href + '&userId=' + userId, {
        onOpen: () => {
            setSubmitDisabled(false);
            console.log('Connected as ' + userId);
        },
        onMessage: (event) => {
            // Destructure the event_type and message from the parsed event.data
            // const { event_type, message } = JSON.parse(event.data);
            const message = JSON.parse(event.data).message;
            const event_type = JSON.parse(event.data).event_type;

            setMessages((prevMessages) => {
                // Create a copy of the previous messages array
                const updatedMessages = [...prevMessages];

                if (event_type === "new") {
                    console.log('Event Type: ' + event_type);

                    // If event_type is "new", create a new message object for Gale
                    updatedMessages.push({
                        id: Date.now(),
                        text: message,
                        sender: 'Gale',
                        avatar: galeProfilePic,
                    });

                    console.log(updatedMessages);
                } else {
                    // If event_type is not "new", find the last message from Gale
                    const lastGaleMessageIndex = updatedMessages.findLastIndex(
                        (msg) => msg.sender === 'Gale'
                    );

                    if (lastGaleMessageIndex >= 0) {
                        // If the last message from Gale exists
                        const currentMessage = updatedMessages[lastGaleMessageIndex];

                        // Update the last message from Gale only if the incoming message is longer
                        if (message.length > currentMessage.text.length) {
                            updatedMessages[lastGaleMessageIndex] = {
                                ...currentMessage,
                                text: message,
                            };
                        }
                    } else {
                        // If there are no previous messages from Gale, create a new message object
                        updatedMessages.push({
                        id: Date.now(),
                        text: message,
                        sender: 'Gale',
                        avatar: galeProfilePic,
                        });
                    }
                }

                // Return the updated messages array
                return updatedMessages;
            });
          },
        shouldReconnect: (closeEvent) => true
    });

    const createInitialMessage = () => {
        document.title = chatTopicData.page_title;

        const systemMessage = {
            id: userId,
            text: 'User ID',
            sender: 'System',
            topic: topic
        };

        // if (String(chatTopicData.page_title).includes('Sales AI Agent')) {
        if (String(topic).includes('gfai')) {
            // console.log('Sales AI Agent');
            // console.log("Team ID:" + topic.split('-').slice(0, 3).join('-'));
            // console.log("Contact ID:" + topic.split('-')[3]);

            fetch('https://1w0ddg9746.execute-api.us-east-2.amazonaws.com/contactdatarequest', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': Config.contactdatarequest,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'access_id': getAccessId(),
                    'chat_with': chatWith,
                    'contact_id': topic.split('-')[3]
                })
            })
                .then((response) => response.json())
                .then((response) => {
                    setPageHeader(chatTopicData.page_header + ' ' + response.first_name + ' ' + response.last_name);
                    let initialMessageText = chatWith;

                    if (chatWith === "CRM Notes" || chatWith === '') {
                        let contactNotes = response.notes.map((item, index) => `${index + 1}. ${item}`).join('\n');
                        initialMessageText = 
                            chatTopicData.initial_message.replace(new RegExp('\\$contact_name', 'g'), response.first_name) + '\n\n' + 
                            'Here are the CRM notes on ' + response.first_name + ':\n\n' + 
                            contactNotes;
                    } else if (chatWith === "Call") {
                        let business_calls = response.business_calls;
                        initialMessageText = 
                            chatTopicData.initial_message.replace(new RegExp('\\$contact_name', 'g'), response.first_name) + '\n\n' + 
                            'Here is the call transcript from ' + response.first_name + '\'s last call:\n\n' + 
                            business_calls[0].transcript;
                    }

                    const message = {
                        id: Date.now(),
                        text: initialMessageText,
                        sender: 'Gale',
                        avatar: galeProfilePic
                    };

                    setMessages([systemMessage, message]);
                })
                .catch(function (error) {
                    console.log('Error:', error);
                    setSubmitDisabled(false);
                });
        } else {
            setPageHeader(chatTopicData.page_header);

            const message = {
                id: Date.now(),
                text: chatTopicData.initial_message,
                sender: 'Gale',
                avatar: galeProfilePic
            };
            
            setMessages([systemMessage, message]);
        }  
    };

    const getAccessId = () => {
        return `${localStorage.getItem('user_id')}-${localStorage.getItem('optimus')}`;
    }

    const handleChatWithChange = (event) => {
        console.log('handleChatWithChange -> event.target.value: ' + event.target.value);
        setChatWith(event.target.value);
        // createInitialMessage();
    };

    const handleSendMessage = () => {
        if (!newMessage.trim()) return; // Ignore empty messages.
        
        const updatedMessages = [
            ...messages,
            {
                id: Date.now(),
                text: newMessage,
                sender: 'You',
                avatar: robotProfilePic
            },
            {
                id: Date.now() + 1,
                text: 'Gale is reading your message...',
                sender: 'Gale',
                avatar: galeProfilePic
            }
        ];
        console.log(updatedMessages);
        setMessages(updatedMessages);
        setNewMessage('');
        
        fetch('https://3p5i2ef2f3.execute-api.us-east-2.amazonaws.com/aichatbotchatrequest', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.aichatbotchatrequest,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedMessages)
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
                setShowProgress(false);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const startRecording = () => {
        setIsRecording(true);
        Mp3Recorder.start()
            .then(() => {})
            .catch((error) => {
                console.error('Error starting recording:', error);
                alert('Error starting recording.');
            });
    };

    const stopRecording = async () => {
        setIsRecording(false);
        Mp3Recorder.stop()
            .getMp3()
            .then(([buffer, blob]) => {
                uploadAndTranscribe(blob);
            })
            .catch((error) => {
                console.error('Error stopping recording:', error);
                alert('Error stopping recording.');
            });
    };

    const uploadAndTranscribe = async (mp3Blob) => {
        const formData = new FormData();
        formData.append('audio', mp3Blob, 'recording.mp3');
        setOutput('Uploading audio recording...')

        try {
            const response = await fetch('https://xyr44fotj7.execute-api.us-east-2.amazonaws.com/callmentorrequest', {
                method: 'POST',
                headers: {
                    'Authorization': Config.callmentorrequest,
                    'Content-Type': 'multipart/form-data',
                    'X-Request-ID': requestId(),
                    'X-Original-Filename': 'recording.mp3',
                    'X-Topic': topic
                },
                body: formData,
            });

            const data = await response.json();
            setOutput('Audio file upload: ' + data.result)
        } catch (error) {
            console.error('Error uploading audio file:', error);
            setSubmitDisabled(false);
        }
    };

    const requestId = () => {
        return `${localStorage.getItem('user_id')}-${localStorage.getItem('optimus')}-text`;
    }

    useEffect(() => {
        if (chatWith) {
            createInitialMessage();
        }
    }, [chatWith]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass('p-0');

		createInitialMessage();
        textareaRef.current.focus();
		
		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass('');
		};
    }, []);

	return (
		<div>
			<div 
				style={{ 
					color: 'white', 
					display: 'flex', 
					flexDirection: 'column', 
					justifyContent: 'space-between', // This will push your message container and form to opposite ends
					minHeight: '95.0vh', // 93.0vh 82.0vh or try `height: 100%` to make it flexible
					alignItems: 'center', // This will center everything horizontally
					padding: '20px' // Add padding to the entire container if needed
				}}
				>
				<style>
					{`
						.responsive-div {
							width: 50%; /* default width */
							align-items: center;
							// margin-bottom: 20px;
						}
							
						@media (max-width: 768px) {
							.responsive-div {
								width: 100%;
							}
						}
					`}
				</style>

				<div 
					className="responsive-div" 
					style={{ 
						maxHeight: '100%', 
						overflow: 'auto',
					}}
					>

					<br /><br />

					<h3 className="gradient-text" style={{ marginBottom: '35px' }}>
                        <b>{pageHeader}</b>
                    </h3>

					{messages.filter(message => message.sender === 'Gale' || message.sender === 'You').map((message) => (
						<div key={message.id} style={{ display: 'flex', alignItems: 'flex-start', margin: '10px 0' }}>
							<img 
								src={message.avatar}
								alt="Avatar"
								style={{
									width: '40px',
									height: '40px',
									borderRadius: '50%', // Make the image circular.
									objectFit: 'cover', // Ensure the image covers the area.
									marginRight: '10px', // Add some space between the avatar and the message.
                                    marginTop: '7px'
								}}
							/>
							<div style={{ flex: 1, fontSize: '15px', lineHeight: '1.8', whiteSpace: 'pre-wrap' }}>
                                <span style={{ fontWeight: 'bold' }}>{message.sender}:</span><br />{message.text}
							</div>
						</div>
					))}
					<div ref={messagesEndRef} />
				</div>

				<div className="responsive-div" style={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}>
                    
                    {topic.includes('-') &&
                        <select 
                            value={chatWith}
                            onChange={handleChatWithChange}
                            style={{
                                height: '48px',
                                borderRadius: '18px',
                                padding: '0 15px',
                                border: 'none',
                                outline: 'none',
                                backgroundColor: '#525252',
                                color: 'white',
                                fontSize: '1rem',
                                paddingRight: '30px', // Space for the custom dropdown arrow
                            }}
                        >
                            <option>CRM Notes</option>
                            <option>Call</option>
                        </select>
                    }

					<textarea
						ref={textareaRef}
						value={newMessage}
						maxLength='600'
						onChange={(e) => setNewMessage(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === 'Enter' && !e.shiftKey) { // Checks if Enter key is pressed without the Shift key
								e.preventDefault(); // Prevents the default action to avoid a new line in the textarea
								handleSendMessage(); // Calls the function to send the message
							}
						}}
						style={{ 
							flexGrow: 1,
							boxSizing: 'border-box', // This ensures padding is included in the height calculation
							height: '48px',
							resize: 'none',
							borderRadius: '18px 0 0 18px',
							// padding: '10px',
                            padding: '14.1px 10px', // Adjusted padding to vertically center the text
							border: 'none',
                            marginLeft: '10px',
							marginRight: '-1px',
							outline: 'none',
							backgroundColor: '#525252',
							color: 'white',
							fontSize: '1rem',
                            lineHeight: '20px', // Added line-height to help with vertical centering
						}}
						placeholder="Message Gale..."
					/>
					<button 
						onClick={handleSendMessage} 
						style={{ 
							flexGrow: 0, // Prevent the button from growing
							boxSizing: 'border-box', // This ensures padding is included in the height calculation
							height: '48px', // Slightly larger to cover the border of the textarea
							borderRadius: '0 18px 18px 0', // Rounded corners on the right side only
							border: 'none',
							backgroundColor: '#4a4a4a',
							color: 'white',
							fontSize: '1rem',
							padding: '0 20px',
							marginLeft: '-1px', // Aligns seamlessly with the textarea
							whiteSpace: 'nowrap', // Keeps the text within the button on one line
						}}
						>
						Send
					</button>
                    <button
                        onClick={isRecording ? stopRecording : startRecording}
                        className="btn btn-primary"
                        disabled={submitDisabled}
                        style={{ 
                            flexGrow: 0, // Prevent the button from growing
                            boxSizing: 'border-box', // This ensures padding is included in the height calculation
                            height: '48px', // Set the button height
                            width: '48px', // Set the button width to make it a circle
                            borderRadius: '50%', // Make the button circular
                            border: 'none',
                            backgroundColor: '#4a4a4a', // Match the background color
                            color: 'white', // Match the text color
                            display: 'flex', // Use flexbox to center the icon
                            justifyContent: 'center', // Center the icon horizontally
                            alignItems: 'center', // Center the icon vertically
                            marginLeft: '10px', // Space between the two buttons
                        }}
                        >
                        {isRecording ? <i className="fas fa-stop-circle"></i> : <i className="fas fa-microphone"></i>}
                    </button>
				</div>
        	</div>
		</div>
	)
}

export default AIChatbot;