import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Logout() {
    const navigate = useNavigate();

    const Logout = () => {
        localStorage.removeItem('user_id');
        localStorage.removeItem('optimus');
        localStorage.removeItem('first_name');
        localStorage.removeItem('last_name');
        localStorage.removeItem('team_id');
        console.log('Logged out');
        navigate('/login');
    }

    useEffect(() => {
        Logout();
    }, []);

    return (
        <div className="page-sign d-block py-0">
            Logged out
        </div>
    )
}