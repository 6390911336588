import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Overlay, Tooltip } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from "react-router-dom";
import useWebSocket from 'react-use-websocket';
import Config from '../config.json';
import EditableContactInfo from './EditableContactInfo';
import LoginCheck from '../pages/logincheck';
import '../../custom.css';

import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css';
import 'datatables.net-fixedcolumns-bs5/css/fixedColumns.bootstrap5.min.css';
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css';

const WS_URL = 'wss://6aiolymuhf.execute-api.us-east-2.amazonaws.com/prod';

const $ = require('jquery');
$.DataTable = require('datatables.net');
require('datatables.net-bs5');
require('datatables.net-buttons');
// require('datatables.net-buttons/js/buttons.colVis.min.js');
// require('datatables.net-buttons/js/buttons.flash.min.js');
// require('datatables.net-buttons/js/buttons.html5.min.js');
// require('datatables.net-buttons/js/buttons.print.min.js');
require('datatables.net-buttons-bs5');
require('datatables.net-responsive');
require('datatables.net-responsive-bs5');
require('datatables.net-fixedcolumns');
require('datatables.net-fixedcolumns-bs5');

function CRM() {
    const navigate = useNavigate();
    const audioUploadRef = useRef(null);
    const tableRef = useRef(null);
    const firstTextboxRef = useRef(null);
    // const tooltipTarget = useRef(null);
    const masterCloserRef = useRef(null);
    const chatWithGaleRef = useRef(null);

    const [aiSystem, setAISystem] = useState('');
    const [callSummary, setCallSummary] = useState('');
    const [contactNotes, setContactNotes] = useState({});
    const [crmId, setCRMId] = useState('zh');  // Temporary code
    const [deleting, setDeleting] = useState(false);
    const [editContact, setEditContact] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [noteExpanded, setNoteExpanded] = useState(false);
    const noteInputRef = useRef(null);
    const [notesSummary, setNotesSummary] = useState('');
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [serverMessage, setServerMessage] = useState('');
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [showAudioUploadDialog, setShowAudioUploadDialog] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showProgress, setShowProgress] = useState(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [statusUpdate, setStatusUpdate] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [userId] = useState(localStorage.getItem('user_id'));
    const [aiRecommendations, setAIRecommendations] = useState({
        'recommendation_1': {'recommended_action': '', 'recommended_message': ''},
        'recommendation_2': {'recommended_action': '', 'recommended_message': ''},
        'recommendation_3': {'recommended_action': '', 'recommended_message': ''}
    });
    const [contactsZ, setContactsZ] = useState([{
        'id': '',
        'Full_Name': '',
        'Account_Name': {'name': ''},
        'Title': '',
        'Email': '',
        'Phone': '',
        'Notes': []
    }]);
    const [contacts, setContacts] = useState([{
        'contact_id': '',
        'first_name': '',
        'last_name': '',
        'title': '',
        'company': '',
        'client_status': '',
        'email': '',
        'phone': '',
        'next_action': '',
        'next_action_date': '',
        'notes': []
    }]);
    const [contact, setContact] = useState({
        'first_name': '',
        'last_name': '',
        'title': '',
        'company': '',
        'client_status': '',
        'email': '',
        'phone': '',
        'next_action': '',
        'next_action_date': '',
        'notes': ''
    });
    const [scorecard, setScorecard] = useState({
        'representative_name': '',
        'customer_name': '',
        'dialogue_type': '',
        'assessments': [],
        'assessments_summary': '',
        'dialogue_quotes': [],
        'action_item': '',
        'enhanced_dialogue': [],
    });

    const noNotesMessage = "There are no notes yet for this contact.";

    const { getWebSocket } = useWebSocket(WS_URL + '?sourceSystemUrl=' + window.location.href + '&userId=' + userId, {
        onOpen: () => {
            setSubmitDisabled(false);
            console.log('Connected as ' + userId);
        },
        onMessage: (event) => {
            const message = JSON.parse(event.data).message;
            setSubmitDisabled(false);

            if (message.includes('representative_identity')) {  // Call Grader AI
                setScorecard(JSON.parse(message));
                setStatusUpdate('');
            } else if (message.includes('Call Summary')) {  // Call Summarizer AI
                setCallSummary(message);
                setStatusUpdate('');
            } else if (message.includes('recommended_action')) {  // Master Closer AI
                 setAIRecommendations(JSON.parse(message));
                 setStatusUpdate('');
            } else if (message.includes('Notes Summary')) {  // Notes Summary AI
                setNotesSummary(message.replace('Notes Summary: ', ''));
                setStatusUpdate('');
            } else if (message === 'llm streaming complete') {
                console.log('LLM streaming complete');
            } else if (message === 'Nothing to do') {
                console.log('Nothing to do');
            } else if (message === 'Page refresh directive') {
                console.log("Page refresh in 3 seconds.");
                setShouldRefresh(true);
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            } else {
                if (message.length < 200) {
                    setStatusUpdate(message);
                    setSubmitDisabled(true);
                }
            }
        },
        shouldReconnect: (closeEvent) => !shouldRefresh
    });

    const customRenderer = {
        strong: ({ node, ...props }) => <span style={{ fontWeight: 'bold' }} {...props} />,
    };

    const createContact = () => {
        setShowContactModal(true);
        setEditContact(true);
    };

    const fetchContactList = async () => {
        try {
            const response = await fetch('https://byisudhfwe.execute-api.us-east-2.amazonaws.com/masterclosercontactlist', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': Config.masterclosercontactlist,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'master_closer_id': getMasterCloserId()
                })
            });
      
            const data = await response.json();
            setContacts(data);
        } catch (err) {
            console.log('Error:', err);
        }
    };

    const fetchContactNotes = async () => {
        // Don't fetch notes if they had been previously fetched.
        if (contactNotes[selectedRecord.contact_id]) {
            if (contactNotes[selectedRecord.contact_id][0] === noNotesMessage) {
                setSubmitDisabled(true);
            } else {
                setContactNotes(prevContactNotes => ({
                    ...prevContactNotes,
                    [selectedRecord.contact_id]: prevContactNotes[selectedRecord.contact_id]
                }));
            }
            return;
        }

        setSubmitDisabled(true);
      
        try {
            const response = await fetch('https://xyx68xpssf.execute-api.us-east-2.amazonaws.com/masterclosercontactnotes', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': Config.masterclosercontactnotes,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'contact_id': selectedRecord.contact_id,
                    'first_name': selectedRecord.first_name,
                    'master_closer_id': getMasterCloserId()
                })
            });
    
            const data = await response.json();
            const apiResponse = data.length > 0 ? data : [noNotesMessage];

            setSubmitDisabled(!(data.length > 0));
            
            setContactNotes(prevContactNotes => ({
                ...prevContactNotes,
                [selectedRecord.contact_id]: apiResponse 
            }));
    
        } catch (err) {
            console.log('Error:', err);
            setErrorMessage('Failed to fetch contact notes. Please try again.');
            
        }
    }

    const getMasterCloserAssistance = (event) => {
        if (contactNotes[selectedRecord.contact_id] && contactNotes[selectedRecord.contact_id][0] === noNotesMessage) {
            console.log('Do nothing');
            return;
        }

        console.log(event.target.id);
        resetAISystemVariables();
        setAISystem(event.target.id);
        setStatusUpdate('Activating Master Closer AI Agents...');
        setSubmitDisabled(true);

        fetch('https://koje92w2u7.execute-api.us-east-2.amazonaws.com/mastercloserassistancerequest', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.mastercloserassistancerequest,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'ai_system': event.target.id,
                'contact_id': selectedRecord.contact_id,
                'master_closer_id': getMasterCloserId()
            })
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
            })
            .catch(function (error) {
                console.log('Error:', error);
                setSubmitDisabled(false);
            });
    }

    const getMasterCloserId = () => {
        return `${localStorage.getItem('user_id')}-${localStorage.getItem('optimus')}-text`;
    }

    const handleContactChange = (e) => {
        const { name, value } = e.target;

        setContact((prevContact) => ({
            ...prevContact,
            [name]: value
        }));

        if (selectedRecord) {
            setSelectedRecord((prevSelectedRecord) => ({
                ...prevSelectedRecord,
                [name]: value
            }));
        }

        setErrorMessage('');
    };

    const handleContactSubmit = async (event) => {
        event.preventDefault();
        
        // console.log('handleContactSubmit:', event.target.name, event.target.value);

        if (!contact.first_name) {
            setErrorMessage('Please enter the first name of the contact.');
            console.log('First name missing');
            return;
        } else if (!contact.last_name) {
            setErrorMessage('Please enter the last name of the contact.');
            console.log('Last name missing');
            return;
        }

        // Append new note to notes array.
        if (selectedRecord && contact.notes) {
            setContactNotes(prevNotes => {
                const currentNotes = prevNotes[selectedRecord.contact_id] || [];
                
                // Check if the only existing note is the placeholder.
                const shouldClear = currentNotes.length === 1 && 
                currentNotes[0] === noNotesMessage;
                
                return {
                    ...prevNotes,
                    [selectedRecord.contact_id]: shouldClear 
                        ? [contact.notes]  // If should clear, only add the new note.
                        : [...currentNotes, contact.notes]  // Otherwise, append to existing notes.
                };
            });
        }

        setSubmitDisabled(true);

        try {
            const response = await fetch('https://wbb2bljh47.execute-api.us-east-2.amazonaws.com/crmcontactupsert', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': Config.crmcontactupsert,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'contact_id': selectedRecord ? selectedRecord.contact_id : '',
                    'first_name': contact?.first_name ?? selectedRecord.first_name,
                    'last_name': contact?.last_name ?? selectedRecord.last_name,
                    'title': contact?.title ?? '',
                    'company': contact?.company ?? '',
                    'client_status': contact?.client_status ?? selectedRecord.client_status,
                    'email': contact?.email ?? selectedRecord.email,
                    'phone': contact?.phone ?? selectedRecord.phone,
                    'next_action': contact?.next_action ?? selectedRecord.next_action,
                    'next_action_date': contact?.next_action_date ?? selectedRecord.next_action_date,
                    'notes': contact?.notes ?? selectedRecord.notes,  // contact && contact.notes ? contact.notes : selectedRecord.notes,
                    'master_closer_id': getMasterCloserId()
                })
            });
    
            const result = await response.json();
    
            if (result.contact_id) {
                if (selectedRecord) {
                    setContact(prevContact => ({
                        ...prevContact,
                        notes: ''
                    }));
                    
                    setNoteExpanded(false);
                    console.log('Contact updated: ' + result.contact_id);
                } else {
                    setShowContactModal(false);
                    setSelectedRecord(null);
                    setContacts((prevContacts) => [
                        ...prevContacts,
                        {
                            'contact_id': result.contact_id,
                            'first_name': contact.first_name,
                            'last_name': contact.last_name,
                            'title': contact.title,
                            'company': contact.company,
                            'client_status': contact.client_status,
                            'email': contact.email,
                            'phone': contact.phone,
                            'next_action': contact.next_action,
                            'next_action_date': contact.next_action_date,
                            'notes': contact.notes
                        }
                    ]);
    
                    setEditContact(false);
                    setSubmitDisabled(false);
                    console.log('New contact created: ' + result.contact_id);
                }
            } else {
                setErrorMessage('Error occurred when creating new contact.');
            }
        } catch (err) {
            setErrorMessage('An error occurred. Please try again.');
            console.log('Error:', err);
        } finally {
            setSubmitDisabled(false);
        }
    };

    const handleDeleteContact = async () => {
        setDeleting(true);
        setStatusUpdate('Deleting contact...');
        setSubmitDisabled(true);

        try {
            const response = await fetch('https://scwtd8vkae.execute-api.us-east-2.amazonaws.com/crmcontactdelete', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': Config.crmcontactdelete,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'contact_id': selectedRecord.contact_id,
                    'master_closer_id': getMasterCloserId()
                })
            });

            const result = await response.json();

            if (result.contact_id) {
                setContacts(prevContacts => prevContacts.filter(contact => contact.contact_id !== selectedRecord.contact_id));
                setStatusUpdate('Contact deleted successfully...');
                hideContactModal();
                console.log(result.contact_id + ' delete');
            } else {
                setErrorMessage('Error occurred when deleting contact.');
            }
        } catch (err) {
            setErrorMessage('An error occurred. Please try again.');
            console.log('Error:', err);
        } finally {
            setDeleting(false);
            setShowDeleteConfirmation(false);
            setSubmitDisabled(false);
        }
    };

    const hideContactModal = () => {
        setShowContactModal(false);
        setContact({
            'first_name': '',
            'last_name': '',
            'company': '',
            'email': '',
            'phone': '',
            'notes': ''
        });
        setSelectedRecord(null);
        setNotesSummary('');
        setStatusUpdate('');
        setSubmitDisabled(false);
        resetAISystemVariables();
    };

    const openAudioUploadDialog = (event) => {
        console.log(event.target.id);
        resetAISystemVariables();
        setAISystem(event.target.id);
        setStatusUpdate(event.target.id + ': Select an audio file to transcribe...');
        audioUploadRef.current.click();
    };
    
    const handleAudioUpload = (event) => {
        console.log(event.target.files[0] ? event.target.files[0].name : '');

        if (!event.target.files[0]) {
            return
        }

        setSubmitDisabled(true);
        setStatusUpdate('Uploading audio file and activating ' + aiSystem + ' Agents...');

        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('audio', file, file.name);
        
        fetch('https://e80cevazaf.execute-api.us-east-2.amazonaws.com/callgraderscorecardrequest', {
            method: 'POST',
            headers: {
                'Authorization': Config.callgraderscorecardrequest,
                'X-Call-Handler': aiSystem,
                'X-Contact-ID': selectedRecord.contact_id,
                'X-Dialogue-ID': getMasterCloserId(),
                'X-Original-Filename': file.name
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('API response:', data);
            })
            .catch((error) => {
                console.error('Error:', error);
                setSubmitDisabled(false);
            });
        
        // Reset the file input value.
        event.target.value = null;
    };

    const renderWithLineBreaks = (text) => {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    const resetAISystemVariables = () => {
        setAIRecommendations({
            'recommendation_1': {'recommended_action': '', 'recommended_message': ''},
            'recommendation_2': {'recommended_action': '', 'recommended_message': ''},
            'recommendation_3': {'recommended_action': '', 'recommended_message': ''}
        });
        setAISystem('');
        setCallSummary('');
        setEditContact(false);
        setNoteExpanded(false);
        setScorecard({'representative_name': ''});
    }

    useEffect(() => {
        fetchContactList();
      
        return () => {
            if (tableRef.current) {
                tableRef.current.destroy();
            }
        };
    }, []);

    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.destroy();
        }

        let columns;

        if (1 == 0) {
            columns = [
                { data: 'id' },
                { data: 'Full_Name' },
                { data: 'Account_Name.name' },
                { data: 'Email' },
                { data: 'Phone' }
            ];
        } else {
            columns = [
                { data: 'contact_id' },
                { data: 'first_name' },
                { data: 'last_name' },
                { data: 'company' },
                { data: 'email' },
                { data: 'phone' }
            ];
        }
  
        tableRef.current = $('#datatableDefault').DataTable({
            dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center mt-2'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
            lengthMenu: [10, 20, 30, 40, 50],
            pageLength: 20,
            responsive: true,
            buttons: [
                { extend: 'print', className: 'btn-sm ms-2' },
                { extend: 'csv', className: 'btn-sm' }
            ],
            order: [[1, 'asc']],
            data: contacts,
            columns: columns
        });

        $('#datatableDefault tbody').on('click', 'tr', function () {
            const rowData = tableRef.current.row(this).data();
            setSelectedRecord(rowData);
            setContact({ ...rowData, notes: '' });
            setShowContactModal(true);
        });
    }, [contacts]);

    useEffect(() => {
        if (editContact) {
            firstTextboxRef.current.focus();
        }
    }, [editContact]);

    useEffect(() => {
        if (selectedRecord) {
            fetchContactNotes();
        }
    }, [selectedRecord]);

    useEffect(() => {
        
    }, [showTooltip]);

    return (
        <div>
            <LoginCheck />

            <h1 className="page-header gradient-text">
                <span className="gradient-text">AI CRM</span> <span className='header-tagline'>CRM with personalized AI sidekicks</span>
            </h1>

            <div>
                <button
                    id="Create Contact"
                    onClick={createContact}
                    className="btn btn-primary"
                    style={{ fontSize: "12px", width: "130px" }}>
                    Create Contact
                </button><br /><br />
            </div>

            <table id="datatableDefault" className="table text-nowrap w-100 table-hover-effect" style={{ cursor: "pointer" }}>
                <thead>
                    <tr>
                    <th className="text-start">Contact ID</th>
                    <th className="text-start">First Name</th>
                    <th className="text-start">Last Name</th>
                    <th className="text-start">Company</th>
                    <th className="text-start">Email</th>
                    <th className="text-start">Phone</th>
                    </tr>
                </thead>
            </table>

            <Modal 
                show={showContactModal} 
                onHide={hideContactModal} 
                dialogClassName="custom-modal"
                className="transparent-modal"
                style={{ background: "linear-gradient(to right, #224be0, #feb47b)", width: editContact ? "400px" : "" }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title className="gradient-text">
                        {selectedRecord ? selectedRecord.first_name + ' ' + selectedRecord.last_name : 'Create New Contact'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflowY: "auto" }}>
                    {(selectedRecord) &&
                        <div>
                            <div className="contact-info">
                                <EditableContactInfo
                                    selectedRecord={selectedRecord}
                                    onUpdate={handleContactChange}
                                    onSubmit={handleContactSubmit}
                                />
                            </div>
                            
                            <br />

                            <div className="mt-3">
                                {contactNotes[selectedRecord.contact_id] && contactNotes[selectedRecord.contact_id].length > 0 ?
                                    <div className="mt-3">
                                        <h5>Notes:</h5>
                                        <ul>
                                            {contactNotes[selectedRecord.contact_id].map((note, index) => (
                                                <li key={index}>{note}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    : <>Loading CRM Notes...</>
                                }
                            </div>

                            <div className="note-section">
                                <div className="note-input">
                                    <textarea
                                        id="notes"
                                        name="notes"
                                        className="form-control"
                                        placeholder="Add a note..."
                                        value={contact.notes}
                                        onChange={handleContactChange}
                                        onClick={() => setNoteExpanded(true)}
                                        ref={noteInputRef}
                                        style={{ border: noteExpanded ? "1px solid #ccc" : "none" }}
                                    />

                                    {noteExpanded && (
                                        <div className="note-actions">
                                            <Button 
                                                variant="primary" 
                                                onClick={handleContactSubmit}
                                                style={{ width: "100px", marginRight: "20px" }}
                                            >
                                                Save
                                            </Button>
                                            <Button 
                                                variant="secondary" 
                                                onClick={() => {
                                                    setNoteExpanded(false);
                                                }}
                                                style={{ width: "100px" }}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {notesSummary &&
                                <>
                                <div className="mt-3">
                                    <ReactMarkdown components={customRenderer}>{`**Notes Summary:** ${notesSummary}`}</ReactMarkdown>
                                </div>
                                <br />
                                </>
                            }

                            <div className="agent-buttons">
                                <button
                                    id="Master Closer AI"
                                    onClick={getMasterCloserAssistance}
                                    className="btn btn-primary"
                                    disabled={!selectedRecord}
                                    ref={masterCloserRef}
                                    style={{ fontSize: "12px", width: "130px" }}
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                >
                                        Master Closer AI
                                </button>
                            
                                <input
                                    type="file"
                                    accept="audio/*"
                                    onChange={handleAudioUpload}
                                    ref={audioUploadRef}
                                    style={{ display: "none" }}
                                />

                                <button
                                    id="Call Summarizer AI"
                                    onClick={openAudioUploadDialog}
                                    className="btn btn-primary"
                                    disabled={!selectedRecord}
                                    style={{ fontSize: "12px", width: "130px" }}
                                >
                                    Call Summarizer AI
                                </button>
                                
                                <button
                                    id="Call Grader AI"
                                    onClick={openAudioUploadDialog}
                                    className="btn btn-primary"
                                    disabled={!selectedRecord}
                                    style={{ fontSize: "12px", width: "130px" }}
                                >
                                    Call Grader AI
                                </button>

                                <button
                                    id="Chat with Gale"
                                    onClick={() => {
                                        if (contactNotes[selectedRecord.contact_id] && contactNotes[selectedRecord.contact_id][0] === noNotesMessage) {
                                            console.log('Do nothing');
                                        } else {
                                            const url = `${window.location.protocol}//${window.location.host}/chat/${selectedRecord.team_id}-${selectedRecord.contact_id}`;
                                            window.open(url, '_blank', 'noopener,noreferrer');    
                                        }
                                    }}
                                    className="btn btn-primary"
                                    disabled={!selectedRecord}
                                    ref={chatWithGaleRef}
                                    style={{ fontSize: "12px", width: "130px" }}
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                >
                                    Chat with Gale
                                </button>

                                <Overlay 
                                    target={masterCloserRef.current}
                                    show={showTooltip && contactNotes[selectedRecord.contact_id] && contactNotes[selectedRecord.contact_id][0] === noNotesMessage}
                                    placement="top"
                                >
                                    {(props) => (
                                        <Tooltip id="master-closer-tooltip" {...props}>
                                            This is not available yet because there are no notes for this contact.
                                        </Tooltip>
                                    )}
                                </Overlay>

                                <Overlay 
                                    target={chatWithGaleRef.current}
                                    show={showTooltip && contactNotes[selectedRecord.contact_id] && contactNotes[selectedRecord.contact_id][0] === noNotesMessage}
                                    placement="top"
                                >
                                    {(props) => (
                                        <Tooltip id="chat-with-gale-tooltip" {...props}>
                                            This is not available yet because there are no notes for this contact.
                                        </Tooltip>
                                    )}
                                </Overlay>
                            </div>

                            <div>
                                <br />
                                
                                {aiSystem === 'Call Grader AI' && scorecard.representative_name && 
                                    <>
                                    <b>Call Grader AI Assessment of {scorecard.representative_name}'s Call with Customer {scorecard.customer_name}</b><hr />
                                    <ol>
                                        {scorecard.assessments.map((assessment, index) => {
                                            const parts = assessment.split(":");
                                            const dialogue_quote = scorecard.dialogue_quotes[index];

                                            let dialogueQuoteElements;
                                            if (dialogue_quote) {
                                                // Split the dialogue into lines
                                                const lines = dialogue_quote.split('\n');
                                                dialogueQuoteElements = lines.map((line, lineIndex) => {
                                                    // Find the index of the first colon in each line
                                                    const firstColonIndex = line.indexOf(':');
                                                    if (firstColonIndex !== -1) {
                                                        // Split the line at the first colon
                                                        const firstPart = line.substring(0, firstColonIndex);
                                                        const remaining = line.substring(firstColonIndex + 1);
                                                        return (
                                                            <React.Fragment key={lineIndex}>
                                                                <b>{firstPart}</b>{`:${remaining}`}
                                                                {lineIndex < lines.length - 1 && <br />}
                                                            </React.Fragment>
                                                        );
                                                    } else {
                                                        // If no colon is present in the line, display the line as is
                                                        return (
                                                            <React.Fragment key={lineIndex}>
                                                                {line}
                                                                {lineIndex < lines.length - 1 && <br />}
                                                            </React.Fragment>
                                                        );
                                                    }
                                                });
                                            }

                                            return (
                                                <li key={index}>
                                                    <b>{parts[0]}</b>{parts[1] && `:${parts[1]}`}<br /><br />
                                                    {dialogueQuoteElements}<br /><br />
                                                </li>
                                            );
                                        })}

                                    </ol>
                                    </>
                                }

                                {aiSystem === 'Call Summarizer AI' && callSummary &&
                                    <div>
                                        {callSummary.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </div>
                                }

                                {aiSystem === 'Master Closer AI' && aiRecommendations.recommendation_1.recommended_action &&
                                    <>
                                    {aiRecommendations.recommendation_1 && (
                                        <>
                                        <div>
                                            <u>AI Agent One Recommended Next Step</u><br /><br />
                                            {renderWithLineBreaks(aiRecommendations.recommendation_1.recommended_action)}
                                        </div><br />
                                        
                                        <div>
                                            <u>AI Agent One Recommended Email:</u><br /><br />
                                            {renderWithLineBreaks(aiRecommendations.recommendation_1.recommended_message)}
                                        </div>
                                        </>
                                    )}

                                    <hr />

                                    {aiRecommendations.recommendation_2 && (
                                        <>
                                        <div>
                                            <u>AI Agent Two Recommended Next Step</u><br /><br />
                                            {renderWithLineBreaks(aiRecommendations.recommendation_2.recommended_action)}
                                        </div><br />
                                        
                                        <div>
                                            <u>AI Agent Two Recommended Email</u><br /><br />
                                            {renderWithLineBreaks(aiRecommendations.recommendation_2.recommended_message)}
                                        </div>
                                        </>
                                    )}
                                    </>
                                }

                                {statusUpdate &&
                                
                                    statusUpdate 
                                    
                                }
                            </div>
                        </div>
                    }

                    {editContact && 
                        <div className="form-group">
                            <div>
                                <div className="form-group mb-3">
                                    <label htmlFor="first_name">First Name *</label>
                                    <input type="text" id="first_name" name="first_name" ref={firstTextboxRef} className="form-control" style={{ width: "220px" }} value={contact.first_name} onChange={handleContactChange} autoComplete="new-password" />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="last_nametName">Last Name *</label>
                                    <input type="text" id="last_name" name="last_name" className="form-control" style={{ width: "220px" }} value={contact.last_name} onChange={handleContactChange} />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="title">Title</label>
                                    <input type="text" id="title" name="title" className="form-control" style={{ width: "220px" }} value={contact.title} onChange={handleContactChange} />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="company">Company</label>
                                    <input type="text" id="company" name="company" className="form-control" style={{ width: "220px" }} value={contact.company} onChange={handleContactChange} />
                                </div>
                                {/* <div className="form-group mb-3">
                                    <label htmlFor="client_status">Client Status</label>
                                    <select
                                        id="client_status"
                                        name="client_status"
                                        className="form-control"
                                        value={contact.client_status}
                                        onChange={handleContactChange}
                                        style={{
                                            backgroundColor: "#2c3e50",
                                            color: "#ecf0f1",
                                            border: "1px solid #34495e",
                                            width: "220px"
                                        }}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Lead">Lead</option>
                                        <option value="Qualified">Qualified</option>
                                        <option value="Terminated">Terminated</option>
                                        <option value="Offer Sent">Offer Sent</option>
                                        <option value="Purchase in Progress">Purchase in Progress</option>
                                        <option value="Closed-Won">Closed-Won</option>
                                        <option value="Closed-Lost">Closed-Lost</option>
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="email">Email</label>
                                    <input type="text" id="email" name="email" className="form-control" style={{ width: "220px" }} value={contact.email} onChange={handleContactChange} />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="phone">Phone</label>
                                    <input type="text" id="phone" name="phone" className="form-control" style={{ width: "220px" }} value={contact.phone} onChange={handleContactChange} />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="next_action">Next Action</label>
                                    <input type="text" id="next_action" name="next_action" className="form-control" style={{ width: "220px" }} value={contact.next_action} onChange={handleContactChange} />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="next_action_date">Next Action Date</label>
                                    <input type="text" id="next_action_date" name="next_action_date" className="form-control" style={{ width: "220px" }} value={contact.next_action_date} onChange={handleContactChange} />
                                </div> */}
                            </div>

                            {/* <textarea id="notes" name="notes" className="form-control mt-3" placeholder="CRM notes" rows="8" value={contact.notes} onChange={handleContactChange}></textarea><br /> */}
                            
                            <button
                                onClick={handleContactSubmit}
                                className="btn btn-primary mt-2"
                                style={{ fontSize: "12px", width: "130px" }}
                                disabled={submitDisabled}>
                                Submit
                            </button>

                            <div style={{ color: "red", marginTop: "25px" }}>{errorMessage}</div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <div>
                        {selectedRecord && (
                            <Button
                                variant="danger"
                                onClick={() => setShowDeleteConfirmation(true)}
                                style={{ backgroundColor: "#dc3545", width: "120px" }}
                                disabled={!selectedRecord}>
                                Delete Contact
                            </Button>
                        )}
                    </div>
                    <div>
                        <Button variant="secondary" onClick={hideContactModal} style={{ backgroundColor: "#224be0", width: "120px" }}>Close</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal
                show={showDeleteConfirmation}
                onHide={() => setShowDeleteConfirmation(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{statusUpdate || 'Confirm Deletion'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this contact? This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                            onClick={() => setShowDeleteConfirmation(false)}
                            disabled={deleting}
                    >
                        Cancel
                    </Button>
                    <Button variant="danger"
                            onClick={handleDeleteContact}
                            disabled={deleting}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showAudioUploadDialog}
                onHide={() => setShowAudioUploadDialog(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Upload Audio File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        type="file"
                        accept="audio/*"
                        onChange={handleAudioUpload}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAudioUploadDialog(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CRM;