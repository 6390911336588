import React, { useEffect, useContext, useRef, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import Config from '../config.json';

function PagesRegister() {
	const context = useContext(AppSettings);
	const inputRef = useRef();
	const [buttonText, setButtonText] = useState('Sign Up');
	const [errorMessage, setErrorMessage] = useState('');
	const [redirect, setRedirect] = useState(false);
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const [account, setAccount] = useState({
        'full_name': '',
		'email': '',
        'password': ''
    });

	const handleChange = (e) => {
        const { name, value } = e.target;

        setAccount({
            ...account,
            [name]: value
        });

        setErrorMessage('');
    };

	const handleSubmit = (event) => {
        event.preventDefault();

		if (!account.full_name) {
			setErrorMessage('Please enter your name.');
			return true;
		} else if (!account.email) {
			setErrorMessage('Please enter your email.');
			return true;
		} else if (!account.password) {
			setErrorMessage('Please create a password.');
			return true;
		}

        setButtonText('Creating Account...');
		setSubmitDisabled(true);

        fetch('https://2qsgrp0848.execute-api.us-east-2.amazonaws.com/usersignup', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.usersignup,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'full_name': account.full_name,
				'user_id': account.email,
                'password': account.password
            })
        })
            .then((response) => response.json())
            .then((response) => {
				if (response.token) {
					localStorage.setItem('user_id', response.user_id);
					localStorage.setItem('optimus', response.token);
					localStorage.setItem('first_name', response.first_name);
					localStorage.setItem('last_name', response.last_name);
					setRedirect(true);
				} else {
					setErrorMessage(response.error);
					setButtonText('Sign Up');
                    setSubmitDisabled(false);
				}
				
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }
	
	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass('p-0');
		
		inputRef.current.focus();

		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass('');
		};
	}, []);
	
	if (redirect) {
		console.log('Redirecting...');
		return <Navigate to='/' />;
	}

	return (
		<div className="register">
			<div className="register-content">
				<form onSubmit={handleSubmit}>
					<h2 className="text-center">Sign up to<br />Gale Force AI</h2>
					<p className="text-inverse text-opacity-50 text-center">
						{errorMessage ?
							<span className="text-danger"><b>{errorMessage}</b></span>
							: <span>Embrace AI and leave your competitors behind.</span>
						}
					</p>
					<div className="mb-3">
						<label className="form-label">Name <span className="text-danger">*</span></label>
						<input type="text" id="full_name" name="full_name" value={account.full_name} onChange={handleChange} className="form-control form-control-lg bg-white bg-opacity-5" placeholder="" ref={inputRef} />
					</div>
					<div className="mb-3">
						<label className="form-label">Email <span className="text-danger">*</span></label>
						<input type="text" id="email" name="email" value={account.email} onChange={handleChange} className="form-control form-control-lg bg-white bg-opacity-5" placeholder="" autoComplete="off" />
					</div>
					<div className="mb-3">
						<label className="form-label">Password <span className="text-danger">*</span></label>
						<input type="password" id="password" name="password" value={account.password} onChange={handleChange} className="form-control form-control-lg bg-white bg-opacity-5" />
					</div>

					{false &&
					<>
					<div className="mb-3">
						<label className="form-label">Confirm Password <span className="text-danger">*</span></label>
						<input type="password" className="form-control form-control-lg bg-white bg-opacity-5" />
					</div>
					<div className="mb-3">
						<label className="form-label">Country <span className="text-danger">*</span></label>
						<select className="form-select form-select-lg bg-white bg-opacity-5">
							<option>United States</option>
						</select>
					</div>
					<div className="mb-3">
						<label className="form-label">Gender <span className="text-danger">*</span></label>
						<select className="form-select form-select-lg bg-white bg-opacity-5">
							<option>Female</option>
						</select>
					</div>
					<div className="mb-3">
						<label className="form-label">Date of Birth <span className="text-danger">*</span></label>
						<div className="row gx-2">
							<div className="col-6">
								<select className="form-select form-select-lg bg-white bg-opacity-5">
									<option>Month</option>
								</select>
							</div>
							<div className="col-3">
								<select className="form-select form-select-lg bg-white bg-opacity-5">
									<option>Day</option>
								</select>
							</div>
							<div className="col-3">
								<select className="form-select form-select-lg bg-white bg-opacity-5">
									<option>Year</option>
								</select>
							</div>
						</div>
					</div>
					<div className="mb-3">
						<div className="form-check">
							<input className="form-check-input" type="checkbox" id="customCheck1" />
							<label className="form-check-label" htmlFor="customCheck1">I have read and agree to the <a href="#/">Terms of Use</a> and <a href="#/">Privacy Policy</a>.</label>
						</div>
					</div>
					</>
					}

					<div className="mb-3" style={{ fontSize: "12px" }}>
						Signing up constitutes agreement to our policies.
					</div>
					<div className="mb-3">
						<button type="submit" className="btn btn-outline-theme btn-lg d-block w-100" disabled={submitDisabled}>{buttonText}</button>
					</div>
					<div className="text-inverse text-opacity-50 text-center">
						Already have an account? <Link to="/pages/login">Sign In</Link>
					</div>
				</form>
			</div>
		</div>
	)
}

export default PagesRegister;