import React, { useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import useWebSocket from 'react-use-websocket';
import Config from '../config.json';
import LoginCheck from '../pages/logincheck';
import '../../custom.css';

const WS_URL = 'wss://6aiolymuhf.execute-api.us-east-2.amazonaws.com/prod';

const RedFlagAI = () => {
    const fileInputRef = useRef(null);
    const [dataFilterReportType, setDataFilterReportType] = useState('red_flags');
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const [presignedUrl, setPresignedUrl] = useState('');
    const [showLogicModal, setShowLogicModal] = useState(false);
    const [statusUpdate, setStatusUpdate] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [userId] = useState(localStorage.getItem('user_id'));
    const [vettedLogic, setVettedLogic] = useState('');

    const { getWebSocket } = useWebSocket(WS_URL + '?sourceSystemUrl=' + window.location.href + '&userId=' + userId, {
        onOpen: () => {
            console.log('Connected as ' + userId);
        },
        onMessage: (event) => {
            const message = JSON.parse(event.data).message;

            if (message.includes('https://')) {
                setPresignedUrl(message);
                setStatusUpdate('');
            } else {
                setStatusUpdate(message);
                setPresignedUrl('');
            }
            
            setSubmitDisabled(false);
        },
        shouldReconnect: (closeEvent) => true
    });

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.currentTarget.contains(e.relatedTarget)) return;
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        
        const droppedFile = e.dataTransfer.files[0];
        validateAndSetFile(droppedFile);
    };

    const handleDragEnd = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleFileInputChange = (e) => {
        const selectedFile = e.target.files[0];
        validateAndSetFile(selectedFile);
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        switch (name) {
            case 'reportType':
                setDataFilterReportType(value);
                break;
            default:
                console.warn(`Unhandled input name: ${name}`);
        }
    };

    const handleLogicSubmit = async () => {
        const logicInput = document.getElementById('logicInput').value;
        if (logicInput.trim() === '') {
            setStatusUpdate('Logic input cannot be empty.');
            return;
        }

        // try {
        //     const response = await fetch('https://qgukadh2hg.execute-api.us-east-2.amazonaws.com/redflagdetectionrequest', {
        //         method: 'POST',
        //         headers: {
        //             'Authorization': Config.redflagdetectionrequest,
        //             'Content-Type': 'application/json',
        //             'X-Request-ID': requestId()
        //         },
        //         body: JSON.stringify({ logic: logicInput.trim() })
        //     });

        //     if (response.ok) {
        //         setStatusUpdate(file.name + ' uploaded successfully...');
        //         setFile(null);
        //     } else {
        //         throw new Error('File upload failed');
        //     }
        // } catch (error) {
        //     setError(error.message || 'An unexpected error occurred. Please try again.');
        // } finally {
        //     setSubmitDisabled(false);
        // }
        
        console.log('Logic submitted:', logicInput);
        setVettedLogic('Your logic has been successfully vetted and is ready to be used.');
    };

    const validateAndSetFile = (attachedFile) => {
        console.log(attachedFile.name);

        const allowedTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv'];
        if (attachedFile && allowedTypes.includes(attachedFile.type)) {
            setFile(attachedFile);
            setError('');
            setPresignedUrl('');
            setStatusUpdate('');
        } else {
            setFile(null);
            setError('Please upload only Excel or CSV files.');
        }
    };

    const getContentType = (fileName) => {
        if (fileName.endsWith('.xlsx')) {
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        } else if (fileName.endsWith('.xls')) {
            return 'application/vnd.ms-excel';
        } else if (fileName.endsWith('.csv')) {
            return 'text/csv';
        }
        
        // Default to binary data for unknown types.
        return 'application/octet-stream';
    };

    const handleUpload = async () => {
        if (!file) {
            setError('Please select a file to upload.');
            return;
        }

        setStatusUpdate(file.name + ' will now be uploaded to the server...');
        setSubmitDisabled(true);
        console.log('Uploading ' + file.name);
        console.log('Report type: ' + dataFilterReportType);

        try {
            const response = await fetch('https://qgukadh2hg.execute-api.us-east-2.amazonaws.com/redflagdetectionrequest', {
                method: 'POST',
                headers: {
                    'Authorization': Config.redflagdetectionrequest,
                    'Content-Type': getContentType(file.name),
                    'X-Original-Filename': file.name,
                    'X-Report-Type': dataFilterReportType,
                    'X-Request-ID': requestId()
                },
                body: file,
            });

            if (response.ok) {
                setStatusUpdate(file.name + ' uploaded successfully...');
                setFile(null);
            } else {
                throw new Error('File upload failed');
            }
        } catch (error) {
            setError('An error occurred while uploading the file. Please try again.');
        } finally {
            setSubmitDisabled(false);
        }
    };

    const hideLogicModal = () => {
        setShowLogicModal(false);
        setVettedLogic('');
    };
    
    const requestId = () => {
        return `${localStorage.getItem('user_id')}-${localStorage.getItem('optimus')}`;
    }

    const LinkRenderer = ({ href, children }) => (
        <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
        </a>
    );
    
    const MarkdownRenderer = ({ children }) => {
        const customRenderers = {
            a: LinkRenderer,
            break: () => <br />,
            paragraph: ({ node, ...props }) => <p style={{ marginBottom: '1em' }} {...props} />,
            strong: ({ node, ...props }) => <span style={{ fontWeight: 'bold' }} {...props} />
        };
    
        return (
            <ReactMarkdown components={customRenderers}>
                {children}
            </ReactMarkdown>
        );
    };

    return (
        <div>
            <LoginCheck />

            <h1 className="page-header gradient-text">
                <span className="gradient-text">Red Flag AI</span> <span className='header-tagline'>Uncovers hidden warning signs in your data</span>
            </h1>

            {/* Input fields for additional options and data entry */}
            <div style={{marginBottom: '20px', padding: '20px', backgroundColor: '#1e1e1e', borderRadius: '8px', boxShadow: '0 2px 4px rgba(255, 255, 255, 0.1)'}}>
                <div className="d-flex flex-wrap align-items-center">
                    {/* Dropdown for AI Report selection */}
                    <select name="reportType" className="form-select me-2 mb-2 mb-md-0" aria-label="Select option" style={{flex: '1', minWidth: '200px', backgroundColor: '#2c2c2c', color: '#ffffff', border: '1px solid #444'}} value={dataFilterReportType} onChange={handleFilterChange}>
                        <option value="" disabled>Choose an AI Report</option>
                        <option value="red_flags" style={{backgroundColor: '#ffffff', color: '#000000'}}>Red Flags</option>
                        <option value="sent_offers" style={{backgroundColor: '#ffffff', color: '#000000'}}>Sent Offers</option>
                    </select>
                </div>
            </div>

            {/* Container for file upload */}
            <div style={{maxWidth: '800px', margin: '40px auto', padding: '24px', backgroundColor: '#aef2f5', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'}}>
                <div
                    style={{
                        border: '2px dashed #1890ff',
                        borderRadius: '8px',
                        padding: '64px',
                        textAlign: 'center',
                        backgroundColor: isDragging ? '#e6f7ff' : 'transparent'
                    }}
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDragEnd={handleDragEnd}
                    onDrop={handleDrop}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{margin: '0 auto', color: '#999'}}>
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                        <polyline points="17 8 12 3 7 8"></polyline>
                        <line x1="12" y1="3" x2="12" y2="15"></line>
                    </svg>
                    <p style={{marginTop: '8px', fontSize: '14px', color: '#666'}}>
                        Drag and drop your Excel or CSV file here, or
                    </p>
                    <button
                        onClick={() => fileInputRef.current.click()}
                        style={{marginTop: '8px', color: '#1890ff', background: 'none', border: 'none', cursor: 'pointer'}}
                    >
                        Choose a File
                    </button>
                    <input
                        key={Math.random()}  // Remount the component.
                        ref={fileInputRef}
                        type="file"
                        style={{display: 'none'}}
                        onChange={handleFileInputChange}
                        accept=".xlsx,.xls,.csv"
                    />
                </div>

                {file && (
                    <div style={{color: '#0d5e18', marginTop: '24px', padding: '8px', backgroundColor: 'none', borderRadius: '4px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <span style={{fontSize: '14px', fontWeight: 'bold',  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{file.name}</span>
                        <button
                            onClick={() => {
                                setFile(null);
                                setSubmitDisabled(false);
                                setError('');
                            }}
                            style={{color: '#ff4d4f', background: 'none', border: 'none', cursor: 'pointer'}}
                        >
                            ✕
                        </button>
                    </div>
                )}

                {error && (
                    <div style={{marginTop: '24px', padding: '8px', backgroundColor: 'none', borderRadius: '4px', color: '#ff4d4f', display: 'flex', alignItems: 'center'}}>
                        {/* ⚠️ */}
                        <span style={{fontSize: '14px'}}>{error}</span>
                    </div>
                )}

                <button
                    onClick={handleUpload}
                    className="btn btn-primary"
                    disabled={submitDisabled}
                    style={{
                        marginTop: '24px',
                        width: '100%',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        border: 'none'
                    }}
                >
                    Upload
                </button>
            </div>

            {/* <div className="mt-3" style={{ textAlign: 'center', color: 'lime', fontSize: '14px', marginTop: '20px', fontWeight: '100'}}>
                <MarkdownRenderer>
                    {statusUpdate}
                </MarkdownRenderer>
            </div> */}

            {presignedUrl ?
                <div style={{
                    maxWidth: '345px',
                    margin: '40px auto',
                    padding: '24px',
                    backgroundColor: '#a2f5b6',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px'
                    }}>
                    <div style={{ color: '#006400' }}>
                        <strong>Successful red flag detection!</strong><br /><br />
                        A link to your {dataFilterReportType.replace('_', ' ').charAt(0).toUpperCase() + dataFilterReportType.replace('_', ' ').slice(1)} AI Report has been emailed to {userId}.<br /><br />
                        You can also download the report here:<br /><br />
                        <a href={presignedUrl} style={{ color: 'blue' }}>{dataFilterReportType.replace('_', ' ').charAt(0).toUpperCase() + dataFilterReportType.replace('_', ' ').slice(1)} AI Report</a>
                    </div>
                </div>
                : 
                <div className="mt-3" style={{ textAlign: 'center', color: 'lime', fontSize: '14px', marginTop: '20px', fontWeight: '100'}}>
                    {statusUpdate}
                </div>
            }

            {/* <button
                onClick={() => setShowLogicModal(true)}
                className="btn btn-secondary"
                style={{
                    backgroundColor: '#4CAF50', // A green color
                    color: 'white', // White text for contrast
                    marginTop: '24px',
                    display: 'block',
                    margin: '0 auto',
                    width: '20%',
                    padding: '8px 16px',
                    borderRadius: '4px',
                    border: 'none'
                }}
            >
                Create New Logic
            </button> */}

            <Modal 
                show={showLogicModal} 
                onHide={hideLogicModal} 
                dialogClassName="custom-modal"
                className="transparent-modal"
                style={{ background: "linear-gradient(to right, #224be0, #feb47b)", width: "400px" }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title className="gradient-text">
                        {'Create Logic'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflowY: "auto" }}>
                    <div className="form-group">
                        <label htmlFor="logicInput">Enter your logic:</label>
                        <textarea
                            id="logicInput"
                            className="form-control"
                            rows="4"
                            style={{
                                width: '100%',
                                padding: '10px',
                                marginBottom: '15px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                resize: 'vertical'
                            }}
                        ></textarea>
                    </div>

                    <Button 
                        variant="primary" 
                        onClick={handleLogicSubmit}
                        style={{ 
                            backgroundColor: "#4CAF50", 
                            border: "none", 
                            width: "100%",
                            padding: '10px',
                            borderRadius: '4px'
                        }}
                    >
                        Submit Logic
                    </Button>

                    <div style={{ marginTop: '15px', fontSize: '16px', color: 'white' }}>
                        {vettedLogic}
                    </div>
                    
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <div>
                        <Button variant="secondary" onClick={hideLogicModal} style={{ backgroundColor: "#224be0", width: "120px" }}>Close</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default RedFlagAI;