import React, { useState, useRef, useEffect } from 'react';

const EditableField = ({ label, value, onChange, onSave, options }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [editedValue, setEditedValue] = useState(value);
    const popupRef = useRef(null);
    const inputRef = useRef(null);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setEditedValue(newValue);
        onChange({
            target: { name: label.toLowerCase().replace(/ /g, '_'), value: newValue }
        });
    };

    const handleSave = () => {
        onSave({
            preventDefault: () => {},
            target: { name: label.toLowerCase().replace(/ /g, '_'), value: editedValue }
        });
        setIsEditing(false);
        setIsHovering(false);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedValue(value);
        setIsHovering(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                handleCancel();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditing]);

    const renderEditField = () => {
        if (options) {
            return (
                <select
                    ref={inputRef}
                    value={editedValue}
                    onChange={handleChange}
                    className="edit-input"
                >
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            );
        }
        return (
            <input
                ref={inputRef}
                value={editedValue}
                onChange={handleChange}
                className="edit-input"
            />
        );
    };

    return (
        <div 
            className="contact-info-item"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <div className="contact-info-header">
                <span className="contact-info-label">{label}</span>
                {isHovering && !isEditing && (
                    <button 
                        onClick={() => setIsEditing(true)} 
                        className="edit-button"
                    >
                        ✏️
                    </button>
                )}
            </div>
            {isEditing ? (
                <div 
                    ref={popupRef}
                    className="edit-popup"
                >
                    {renderEditField()}
                    <div className="edit-buttons">
                        <button 
                            onClick={handleSave} 
                            className="save-button"
                        >
                            Save
                        </button>
                        <button 
                            onClick={handleCancel} 
                            className="cancel-button"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            ) : (
                <span className="contact-info-value">{value}</span>
            )}
        </div>
    );
};

const EditableContactInfo = ({ selectedRecord, onUpdate, onSubmit }) => {
    const handleFieldUpdate = (event) => {
        onUpdate(event);
    };

    const handleFieldSave = (event) => {
        onSubmit(event);
    };

    const clientStatusOptions = [
        { value: "", label: "Select Status" },
        { value: "Lead", label: "Lead" },
        { value: "Qualified", label: "Qualified" },
        { value: "Terminated", label: "Terminated" },
        { value: "Offer Sent", label: "Offer Sent" },
        { value: "Purchase in Progress", label: "Purchase in Progress" },
        { value: "Closed-Won", label: "Closed-Won" },
        { value: "Closed-Lost", label: "Closed-Lost" }
    ];

    return (
        <div className="contact-info">
            <EditableField 
                label="Title" 
                value={selectedRecord.title ?? ''} 
                onChange={handleFieldUpdate}
                onSave={handleFieldSave}
            />
            <EditableField 
                label="Company" 
                value={selectedRecord.company ?? ''} 
                onChange={handleFieldUpdate}
                onSave={handleFieldSave}
            />
            <EditableField 
                label="Client Status" 
                value={selectedRecord.client_status ?? ''} 
                onChange={handleFieldUpdate}
                onSave={handleFieldSave}
                options={clientStatusOptions}
            />
            <EditableField 
                label="Email" 
                value={selectedRecord.email ?? ''} 
                onChange={handleFieldUpdate}
                onSave={handleFieldSave}
            />
            <EditableField 
                label="Phone" 
                value={selectedRecord.phone ?? ''} 
                onChange={handleFieldUpdate}
                onSave={handleFieldSave}
            />
            <EditableField 
                label="Next Action" 
                value={selectedRecord.next_action ?? ''} 
                onChange={handleFieldUpdate}
                onSave={handleFieldSave}
            />
            <EditableField 
                label="Next Action Date" 
                value={selectedRecord.next_action_date ?? ''} 
                onChange={handleFieldUpdate}
                onSave={handleFieldSave}
            />
        </div>
    );
};

export default EditableContactInfo;