import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import Config from '../config.json';

function PagesLogin() {
	const context = useContext(AppSettings);
	const inputRef = useRef();
	const [buttonText, setButtonText] = useState('Sign In');
	const [errorMessage, setErrorMessage] = useState('');
	const [redirect, setRedirect] = useState(false);
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const [login, setLogin] = useState({
        'email': '',
        'password': ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setLogin({
            ...login,
            [name]: value
        });

        setErrorMessage('');
    };
	
	const handleSubmit = (event) => {
        event.preventDefault();

		if (!login.email || !login.password) {
			setErrorMessage('Please provide both email and password.');
			return true;
		}

        setButtonText('Signing In...');
		setSubmitDisabled(true);

        fetch('https://quefg0iqb8.execute-api.us-east-2.amazonaws.com/systemauthenticate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.systemauthenticate,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'user_id': login.email,
                'password': login.password
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (response['token'].length === parseInt(process.env.REACT_APP_TOKEN_LENGTH)) {
                    localStorage.setItem('user_id', response.user_id);
                    localStorage.setItem('optimus', response.token);
                    localStorage.setItem('first_name', response.first_name);
                    localStorage.setItem('last_name', response.last_name);
					localStorage.setItem('team_id', response.team_id);
					setRedirect(true);
                } else {
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('optimus');
                    localStorage.removeItem('first_name');
                    localStorage.removeItem('last_name');
					localStorage.removeItem('team_id');
                    setErrorMessage('Invalid username or password');
					setButtonText('Sign In');
                    setSubmitDisabled(false);
                }
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass('p-0');

		inputRef.current.focus();
		
		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass('');
		};
		
		// eslint-disable-next-line
	}, []);
  
	if (redirect) {
		return <Navigate to='/' />;
	}

	return (
		<div className="login">
			<div className="login-content">
				<form onSubmit={handleSubmit}>
					<h1 className="text-center">Sign In To<br />Gale Force AI</h1>
					<div className="text-inverse text-opacity-50 text-center mb-4">

					{errorMessage ?
						<div className="text-danger"><b>{errorMessage}</b></div>
						: <div>For your protection, please verify your identity.</div>
					}
						
					</div>
					<div className="mb-3">
						<label className="form-label">Email Address <span className="text-danger">*</span></label>
						<input type="text" id="email" name="email" value={login.email} onChange={handleChange} className="form-control form-control-lg bg-white bg-opacity-5" placeholder="" ref={inputRef} />
					</div>
					<div className="mb-3">
						<div className="d-flex">
							<label className="form-label">Password <span className="text-danger">*</span></label>
							<a href="#/" className="ms-auto text-inverse text-decoration-none text-opacity-50">Forgot password?</a>
						</div>
						<input type="password" id="password" name="password" value={login.password} onChange={handleChange} className="form-control form-control-lg bg-white bg-opacity-5" placeholder="" />
					</div>
					{/* <div className="mb-3">
						<div className="form-check">
							<input className="form-check-input" type="checkbox" id="customCheck1" />
							<label className="form-check-label" htmlFor="customCheck1">Remember me</label>
						</div>
					</div> */}
					<button type="submit" className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3" disabled={submitDisabled}>{buttonText}</button>
					<div className="text-center text-inverse text-opacity-50">
						Don't have an account yet? <Link to="/signup">Sign up</Link>.
					</div>
				</form>
			</div>
		</div>
	)
}

export default PagesLogin;