import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginCheck = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const team_id = localStorage.getItem('team_id');
        const token = localStorage.getItem('optimus');

        if (team_id === null
            || token === null
            || String(token).length !== parseInt(process.env.REACT_APP_TOKEN_LENGTH)) {
            navigate('/login');
        }
    }, [navigate]);

    return null;
};

export default LoginCheck;